<script lang="ts">
    export let limit: number = 1;
</script>

{#each Array(limit) as _, i}
    <div class="ph-item mb-2 mt-0">
        <div class="ph-col-2">
            <div class="ph-avatar"></div>
        </div>
        <div>
            <div class="ph-row">
                <div class="ph-col-12"></div>
                <div class="ph-col-6"></div>
                <div class="ph-col-6 empty"></div>
                <div class="ph-col-2"></div>
                <div class="ph-col-10 empty"></div>
                <div class="ph-col-6"></div>
                <div class="ph-col-6 empty"></div>
            </div>
        </div>
    </div>
{/each}

<style>
</style>
